<template>
    <div class="wrapper materialPurchase">
        <table class="custom-table">
            <thead>
                <tr>
                    <td class="custom-th custom-th-bg" style="width:0.8rem;">
                        序号
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1rem;">
                        品种
                    </td>
                    <td class="custom-th custom-th-bg" style="width:1.5rem;">
                        项目
                    </td>
                    <template v-for="(item, index) in MaterialPurchase[0].procureType">
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date>0">
                            {{ item.date }}月
                        </td>
                        <td class="custom-th custom-th-bg mongth-th" :key="'th'+index" v-if="item.date==0">
                            总计
                        </td>
                    </template>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in MaterialPurchase">
                    <tr :key="index">
                        <td
                            class="custom-th"
                            :rowspan="item.procureTypeDetailReports.length + 1"
                            :class="{'custom-th-odd':index%2===0,'custom-th-even':index%2===1,'border6':index<MaterialPurchase.length-1}"
                            style="min-width:.6rem"
                        >
                            {{ index+1 }}
                        </td>
                        <td class="custom-th" :class="{'border6': item.procureTypeDetailReports.length<=0&&index<MaterialPurchase.length-1}">
                            {{ item.label }}
                        </td>
                        <td :class="{'border6': item.procureTypeDetailReports.length<=0&&index<MaterialPurchase.length-1}">
                            <table>
                                <tbody>
                                    <tr>
                                        <th style="width:1.5rem">
                                            数量（吨）
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="width:1.5rem">
                                            价格（元/吨）
                                        </th>
                                    </tr>
                                    <tr>
                                        <th style="width:1.5rem">
                                            核定价格（元/吨）
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <template v-for="(k, i) in item.procureType">
                            <td :key="i" :class="{'border6': item.procureTypeDetailReports.length<=0&&index<MaterialPurchase.length-1}">
                                <table v-if="isDrill">
                                    <tbody>
                                        <tr>
                                            <td @click="showDataDrills(k,1)">
                                                <a href="javascript:;">{{ k.total }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k,2)">
                                                <a href="javascript:;">{{ k.price }}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td @click="showDataDrills(k,3)">
                                                <a href="javascript:;">{{ k.price_auth }}</a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table v-else>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {{ k.total }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.price }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ k.price_auth }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </template>
                    </tr>
                    <template v-for="(j, a) in item.procureTypeDetailReports">
                        <tr :key="j.label + a" :class="{'border6': a===item.procureTypeDetailReports.length-1}">
                            <td class="custom-th">
                                {{ j.label }}
                            </td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>数量（吨）</th>
                                        </tr>
                                        <tr>
                                            <th>价格（元/吨）</th>
                                        </tr>
                                        <tr>
                                            <th>核定价格（元/吨）</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <template v-for="(b, c) in j.procureTypeDetailLists">
                                <td :key="c">
                                    <table v-if="isDrill">
                                        <tbody>
                                            <tr>
                                                <td @click="showDataDrills(b,1)">
                                                    <a href="javascript:;">{{ b.total }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td @click="showDataDrills(b,2)">
                                                    <a href="javascript:;">{{ b.price }}</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td @click="showDataDrills(b,3)">
                                                    <a href="javascript:;">{{ b.price_auth }}</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table v-else>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {{ b.total }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ b.price }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ b.price_auth }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    // 物资采购
    name: 'material-purchase',
    components: {},
    props: {
        loadObj: {
            type: [Object],
        },
        queryStr: {
            type: [String],
        },
        reportData: {
            type: [Object],
        },
    },
    data() {
        return {
            // 物质采购数据集合
            MaterialPurchase: [
                { label: '', procureType: [], procureTypeDetailReports: [] },
            ],
            isDrill: true,
        };
    },
    watch: {
        queryStr: function () {
            this.gitMaterialPurchaseData();
        },
    },
    computed: {},
    methods: {
        // 获取物质采购数据
        gitMaterialPurchaseData() {
            this.$axios
                .get('/interfaceApi/report/procure/report/' + this.queryStr)
                .then(res => {
                    this.MaterialPurchase = res;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 数据钻取
        // eslint-disable-next-line max-params
        showDataDrills(rowData, type) {
            let parseField = 0;
            let parsetText = null;
            let showType = null;
            switch (type) {
                case 1:
                    parseField = 1;
                    parsetText = '数量（吨）';
                    break;
                case 2:
                    parseField = 2;
                    parsetText = '价格（元/吨）';
                    showType = 1;
                    break;
                case 3:
                    parseField = 3;
                    parsetText = '核定价格（元/吨）';
                    showType = 1;
                    break;
                default:
                    parseField = 0;
            }
            let istotal = 0;
            let month = 0;
            if (rowData.date === 0) {
                istotal = 1;
                if (this.reportData.month === 0) {
                    month = 12;
                } else {
                    month = this.reportData.month;
                }
            } else {
                istotal = 0;
                month = rowData.date;
            }
            const params = {
                field: parseField,
                text: parsetText,
                year: this.reportData.year,
                month: month,
                business_code: 'yyqk',
                istotal: istotal,
                treeid: this.reportData.treeid,
                type: rowData.type_code,
                name: rowData.type_code,
                firstPop: true,
                sourceReport: 'materialPurchase',
                showType: showType,
            };
            if (rowData.procure_type) {
                params.type = rowData.procure_type;
                params.name = rowData.type_code;
            }
            const pop = this.$toast({
                title: false,
                t_url: 'reportManage/operationTrends/summary/dataDrills/DrillDownPop',
                type: 'eject',
                width: '80%',
                extr: { closeBtn: { width: '.6rem', height: '.6rem', 'line-height': '.6rem' }, params: params },
            });
            window.window.$globalHub.$store.commit('setChartPop', pop);
        },
        isDrillFn() {
            if (this.reportData.org_type === 5 || this.reportData.org_type === 6) {
                this.isDrill = false;
            } else {
                this.isDrill = true;
            }
        },
    },
    created() {
        this.gitMaterialPurchaseData();
    },
    mounted() {
        this.isDrillFn();
    },
};
</script>
<style lang="stylus">
.materialPurchase
    table
        table
            width 100%
            height 100%
            border-collapse collapse
            table-layout fixed
            word-break break-all
            tr
                &:last-child
                    th
                        border none !important
                    td
                        border none !important
                th
                    border none !important
                    border-bottom 1px solid #ebeef5 !important
                    font-weight 400
                    line-height initial !important
                    &:nth-of-type(1)
                        border-right 1px solid #ebeef5 !important
                td
                    border none !important
                    border-bottom 1px solid #ebeef5 !important
                    line-height initial !important
</style>